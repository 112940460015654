"use strict";
import SelectorsMap from "./constants/selectors-map";
export default () => {
  const { prestashop, Theme: { events } } = window;
  const initProductSlide = () => {
    var _a;
    (_a = document.querySelector(SelectorsMap.product.carousel)) == null ? void 0 : _a.addEventListener("slide.bs.carousel", onProductSlide);
  };
  function onProductSlide(event) {
    var _a;
    document.querySelectorAll(SelectorsMap.product.thumbnail).forEach((e) => e.classList.remove("active"));
    (_a = document.querySelector(SelectorsMap.product.activeThumbail(event.to))) == null ? void 0 : _a.classList.add("active");
  }
  initProductSlide();
  prestashop.on(events.updatedProduct, initProductSlide);
  prestashop.on(events.quickviewOpened, initProductSlide);
  function detectQuantityChange() {
    const quantityInput = document.querySelector(
      SelectorsMap.qtyInput.quantityWanted
    );
    const incrementButton = document.querySelector(
      SelectorsMap.qtyInput.increment
    );
    const decrementButton = document.querySelector(
      SelectorsMap.qtyInput.decrement
    );
    if (quantityInput && incrementButton && decrementButton) {
      const triggerEmit = () => {
        const inputValue = parseInt(quantityInput.value, 10);
        const minValue = parseInt(quantityInput.min, 10);
        if (!isNaN(inputValue) && inputValue >= minValue) {
          quantityInput.value = inputValue.toString();
        } else {
          quantityInput.value = minValue.toString();
        }
        prestashop.emit("updateProduct", {
          eventType: "updatedProductQuantity"
        });
      };
      quantityInput.addEventListener("change", triggerEmit);
      incrementButton.addEventListener("click", triggerEmit);
      decrementButton.addEventListener("click", triggerEmit);
    }
  }
  detectQuantityChange();
};
