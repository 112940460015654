"use strict";
import { Collapse } from "bootstrap";
import { isHTMLElement } from "@helpers/typeguards";
import handleCartAction from "../components/UseHandleCartAction";
export default () => {
  const { Theme } = window;
  const voucherCodes = document.querySelectorAll(Theme.selectors.cart.discountCode);
  const cartContainer = document.querySelector(Theme.selectors.cart.container);
  voucherCodes.forEach((voucher) => {
    voucher.addEventListener("click", (event) => {
      event.stopPropagation();
      if (isHTMLElement(event.currentTarget)) {
        const code = event.currentTarget;
        const discountInput = document.querySelector(Theme.selectors.cart.discountName);
        const promoCode = document.querySelector(Theme.selectors.cart.promoCode);
        if (promoCode && discountInput) {
          const formCollapser = new Collapse(promoCode);
          discountInput.value = code.innerText;
          formCollapser.show();
        }
      }
      return false;
    });
  });
  if (cartContainer) {
    cartContainer.addEventListener("click", (event) => {
      const eventTarget = event.target;
      const targetItem = eventTarget.closest(".cart__item");
      const targetValue = targetItem == null ? void 0 : targetItem.querySelector(".js-cart-line-product-quantity");
      const removeButton = targetItem == null ? void 0 : targetItem.querySelector(".remove-from-cart");
      if (targetValue) {
        if (eventTarget.classList.contains("js-increment-button")) {
          if (targetValue.dataset.mode === "confirmation" && Number(targetValue.value) < 1) {
            if (removeButton) {
              removeButton.click();
            }
          }
        }
        if (eventTarget.classList.contains("js-decrement-button")) {
          if (targetValue.getAttribute("value") === "1" && targetValue.getAttribute("min") === "1") {
            if (removeButton) {
              removeButton.click();
            }
          }
        }
      }
      if (eventTarget.dataset.linkAction === Theme.selectors.cart.deleteLinkAction) {
        handleCartAction(event);
      }
    });
  }
};
