"use strict";
const initCategoryTree = () => {
  const categoryTree = document.querySelector(".ps_categorytree");
  if (categoryTree) {
    const accordionButtons = categoryTree.querySelectorAll(".accordion-button");
    accordionButtons.forEach((element) => {
      element.addEventListener("click", () => {
        var _a;
        const isActive = element.getAttribute("aria-expanded") === "true";
        (_a = element.closest(".category-tree__item")) == null ? void 0 : _a.classList.toggle("active", isActive);
      });
    });
  }
};
export default initCategoryTree;
