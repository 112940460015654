"use strict";
import { Dropdown } from "bootstrap";
const ESCAPE_KEY = "Escape";
const ARROW_UP_KEY = "ArrowUp";
const ARROW_DOWN_KEY = "ArrowDown";
const ARROW_LEFT_KEY = "ArrowLeft";
const ARROW_RIGHT_KEY = "ArrowRight";
const initDesktopMenu = () => {
  const { Theme } = window;
  const { desktopMenu: desktopMenuMap } = Theme.selectors;
  const menuItemsLvl0 = document.querySelectorAll(desktopMenuMap.menuItemsLvl0);
  if (menuItemsLvl0) {
    menuItemsLvl0.forEach((element) => {
      element.addEventListener("mouseenter", () => {
        const subMenuTopPosition = element.offsetHeight + element.offsetTop;
        const subMenu = element.querySelector(desktopMenuMap.subMenu);
        subMenu.style.top = `${subMenuTopPosition}px`;
      });
    });
  }
  const dropdownToggles = document.querySelectorAll(desktopMenuMap.dropdownToggles);
  dropdownToggles.forEach((dropdownToggleElement) => {
    const dropdown = new Dropdown(dropdownToggleElement, {
      popperConfig: () => ({ placement: "auto-end" })
    });
    const dropdownElement = dropdownToggleElement.parentElement;
    dropdownElement == null ? void 0 : dropdownElement.addEventListener("mouseover", () => {
      dropdown.show();
      dropdownToggleElement.blur();
    });
    dropdownElement == null ? void 0 : dropdownElement.addEventListener("mouseout", () => {
      dropdown.hide();
    });
    dropdownToggleElement.addEventListener("keydown", (event) => {
      if (event.key === ARROW_LEFT_KEY || event.key === ARROW_RIGHT_KEY) {
        dropdown.show();
      }
      if (event.key === ESCAPE_KEY) {
        dropdown.hide();
      }
    });
  });
  const mainMenuLinks = document.querySelectorAll(desktopMenuMap.dropdownItemAnchor(0));
  mainMenuLinks.forEach((mainMenuLinkElement) => {
    const menuContainerElement = mainMenuLinkElement.nextElementSibling;
    mainMenuLinkElement.addEventListener("keydown", (event) => {
      if (event.key === ARROW_UP_KEY || event.key === ARROW_DOWN_KEY) {
        menuContainerElement == null ? void 0 : menuContainerElement.classList.add("focusing");
        const menuContainerLinks = menuContainerElement == null ? void 0 : menuContainerElement.querySelectorAll(
          desktopMenuMap.dropdownItemAnchor(1)
        );
        if (menuContainerLinks && menuContainerLinks.length) {
          const targetIndex = event.key === ARROW_DOWN_KEY ? 0 : menuContainerLinks.length - 1;
          menuContainerLinks.item(targetIndex).focus();
        }
        menuContainerElement == null ? void 0 : menuContainerElement.classList.remove("focusing");
        event.stopPropagation();
        event.preventDefault();
      }
    });
    menuContainerElement == null ? void 0 : menuContainerElement.addEventListener("keydown", (event) => {
      if (event.key === ESCAPE_KEY) {
        mainMenuLinkElement.focus();
      }
    });
  });
};
export default initDesktopMenu;
